<template>
  <div class="site">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item>基础设置</el-breadcrumb-item>
      <el-breadcrumb-item>微信公众号</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form ref="formRef" :model="form" label-width="150px">
        <el-form-item label="微信公众号APPid">
          <el-input v-model="form.appid"></el-input>
        </el-form-item>
        <el-form-item label="微信公众号Secret">
          <el-input v-model="form.appsecret"></el-input>
        </el-form-item>
        <el-form-item label="公众号名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="公众号描述">
          <el-input v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item label="公众号账号">
          <el-input v-model="form.account"></el-input>
        </el-form-item>
        <el-form-item label="原始ID">
          <el-input v-model="form.originalid"></el-input>
        </el-form-item>
        <el-form-item label="公众号头像">
          <upload :url="form.logo" :filed="'logo'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="公众号二维码">
          <upload :url="form.ewm" :filed="'ewm'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即创建</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '../common/Upload'
export default {
  name: 'Wx',
  components: {
    Upload
  },
  data () {
    return {
      form: {
        appid: '',
        appsecret: '',
        name: '',
        desc: '',
        account: '',
        originalid: '',
        logo: '',
        ewm: ''
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    // 获取信息
    async getInfo () {
      const { data: res } = await this.$http.get('wx')
      if (res.status === 200) {
        this.form.appid = res.data.appid
        this.form.appsecret = res.data.appsecret
        this.form.name = res.data.name
        this.form.desc = res.data.desc
        this.form.account = res.data.account
        this.form.originalid = res.data.originalid
        this.form.logo = res.data.logo
        this.form.ewm = res.data.ewm
      } else {
        this.$message.error(res.msg)
      }
    },
    // 提交表单
    async onSubmit () {
      const { data: res } = await this.$http.post('wx', this.form)
      if (res.status === 200) {
        this.$message.success(res.msg)
        this.$refs.formRef.resetFields()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.form[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.form[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.form[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.form[field].splice(checkedIndex, 1)
      }
    }
  }
}
</script>

<style scoped>

</style>
